<template>
  <vx-card :title="this.pageTitle">
    <template slot="actions">
      <div v-if="multipleSlidesAllowed || (!multipleSlidesAllowed && !slidesAlreadyExist)">
        <vs-button
          v-if="$can('create', 'media-object')"
          size="small" color="success" type="border" icon-pack="feather" icon="icon-image"
          @click="createEntry()"
        >
          Crear
        </vs-button>
      </div>
    </template>

    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-table max-items="10" pagination search stripe :data="entries" :hoverFlat="true">
          <template slot="thead">
            <vs-th v-if="multipleSlidesAllowed" sort-key="priority">Prioridad</vs-th>
            <vs-th sort-key="institution">Institución</vs-th>
            <vs-th sort-key="title">Título</vs-th>
            <vs-th sort-key="publication_status">Estado</vs-th>
            <vs-th>Acciones</vs-th>
          </template>

          <template slot-scope="{data}">
            <tbody>
            <vs-tr :data="record" :key="index" v-for="(record, index) in data">
              <vs-td v-if="multipleSlidesAllowed">
                <p class="font-medium">{{ record.priority }}</p>
              </vs-td>
              <vs-td>
                <p class="font-medium">{{ record.institution.name }}</p>
              </vs-td>
              <vs-td>
                <p class="font-medium">{{ record.settings.title }}</p>
              </vs-td>
              <vs-td>
                <vs-chip :color="record.publication_status | publication_status_color">
                  {{ record.publication_status | publication_status_label }}
                </vs-chip>
              </vs-td>
              <vs-td>
                <div class="flex">
                  <vx-tooltip text="Editar">
                    <vs-button
                      v-if="$can('retrieve', 'media-object') || $can('update', 'media-object')"
                      radius size="large" color="success" type="flat" icon-pack="feather" icon="icon-edit"
                      class="mr-2"
                      @click="retrieveSlide(record.id)"
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Publicar">
                    <vs-button
                      radius size="large" :color="record.in_production ? 'dark' : 'success'" type="flat"
                      icon-pack="feather" icon="icon-send"
                      :disabled="record.in_production"
                      @click="confirmCommit(record)"
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Eliminar">
                    <vs-button
                      v-if="$can('delete', 'media-object')"
                      radius size="large" color="warning" type="flat" icon-pack="feather" icon="icon-trash-2"
                      @click="confirmDelete(record)"
                    />
                  </vx-tooltip>
                </div>
              </vs-td>
            </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </div>
  </vx-card>
</template>

<script>
  import CoreClient from "../../utils/coreClient";
  import EntriesClient from "../../utils/entriesClient";
  import Notifier from "./../../utils/notification";
  import FilterGroups from '@/components/filter-groups/FilterGroups';
  import * as notificationMixins from "../../utils/notificationMixins";

  let notifier = new Notifier();
  const ENTRIES_CLIENT = new EntriesClient("media-objects");
  const INSTITUTIONS_CLIENT = new CoreClient("institutions");

  export default {
    data() {
      return {
        pageTitle: "",
        entries: [],
      };
    },

    mounted() {
      this.$vs.loading();
      this.getEntries(false)
        .then(() => {
          this.setTitle();
          this.$vs.loading.close();
        });
    },

    computed: {
      multipleSlidesAllowed() {
        return ["carousel-index"].includes(this.$route.params.type);
      },

      slidesAlreadyExist() {
        return this.entries.length > 0;
      }
    },

    methods: {
      setTitle() {  // TODO: fix this crappy code
        if (this.$route.params.type === "carousel-index") {
          this.pageTitle = "Carrusel página principal"
        } else {
          let tokens = this.$route.params.type.split("-");
          let segment = tokens.shift();
          let upperCaseSegment = segment.charAt(0).toUpperCase() + segment.substring(1);
          tokens.pop();
          let category = tokens.join(" ");
          let upperCaseCategory = category.charAt(0).toUpperCase() + category.substring(1);
          this.pageTitle = `${upperCaseSegment}: ${upperCaseCategory}`;
        }
      },

      async getEntries(showLoading = true) {
        if (showLoading) this.$vs.loading();
        await ENTRIES_CLIENT.all({
          queryStrings: {
            ...{institution_id: this.$route.params.institution, type: this.$route.params.type}
          }
        })
          .then(response => {
            this.entries = response.data;
            if (showLoading) this.$vs.loading.close();
          })
          .catch(error => {
            if (showLoading) this.$vs.loading.close();
            notifier.notification("error");
          });
      },

      async deleteEntry(entryId) {
        if (entryId !== null && entryId !== undefined && entryId !== "") {
          this.$vs.loading();
          await ENTRIES_CLIENT.delete({pathParameters: {entryId: entryId}})
            .then(response => {
              notifier.notification("deleted");
              this.getEntries()
                .then(() => {
                  this.$vs.loading.close();
                });
            })
            .catch(error => {
              this.$vs.loading.close();
              notifier.alertMessage("error");
            });
        }
      },

      confirmDelete(entry) {
        notifier.deleteMessage().then(result => {
          if (result.value) {
            this.deleteEntry(entry.id);
          }
        });
      },

      confirmCommit(entry) {
        notificationMixins.CommitEntryNotification.fire()
          .then(result => {
            if (result.value) {
              if (entry.id !== null && entry.id !== undefined && entry.id !== "") {
                this.$vs.loading();
                ENTRIES_CLIENT.commit({pathParameters: {entryId: entry.id}})
                  .then(response => {
                    notifier.notification("committed");
                    this.getEntries();
                  })
                  .catch(error => {
                    notifier.alertMessage("error");
                  });
                this.$vs.loading.close();
              }
            }
          });
      },

      createEntry() {
        this.$router.push({
          name: "create-media-object-slide",
          params: {
            institution: this.$route.params.institution,
            type: this.$route.params.type,
          }
        });
      },

      retrieveSlide(slideId) {
        this.$router.push({
          name: "media-object-slide",
          params: {
            institution: this.$route.params.institution,
            type: this.$route.params.type,
            id: slideId,
          }
        });
      }
    },

    components: {
      'filter-groups': FilterGroups
    }
  };
</script>

<style scoped>
  .vs-table--tbody-table .tr-values.selected {
    cursor: default;
  }
</style>
